/* eslint-disable camelcase */
import React, { createContext, useMemo } from 'react';

import {
  AccountStateContextProviderProps,
  AccountState,
  AccountContextType,
} from './types';

const AccountStateContext = createContext<AccountContextType>({
  state: {} as AccountState,
});

const defaultState: AccountState = {};

const formatAuthState = (state: AccountState) => {
  const profileData = state.auth?.user?.profile;
  if (!profileData) {
    return state;
  }

  const { sub = '', email = '', my_savills_user } = profileData;
  return {
    ...state,
    user: {
      userId: sub,
      email,
      firstName: profileData[
        'http://schemas.xmlsoap.org/ws/2005/05/identity/claims/givenname'
      ] as string,
      lastName: profileData[
        'http://schemas.xmlsoap.org/ws/2005/05/identity/claims/surname'
      ] as string,
      phoneNumber: profileData[
        'http://schemas.xmlsoap.org/ws/2005/05/identity/claims/homephone'
      ] as string,
      isUserConnected: my_savills_user === 'true',
    },
  };
};

function AccountStateContextProvider({
  children,
  initialState = defaultState,
  isPreview = false,
}: AccountStateContextProviderProps) {
  const accountState: AccountState = formatAuthState(initialState);
  const initialContextValue = useMemo(
    () => ({
      state: { ...accountState, isPreview },
    }),
    [accountState, isPreview],
  );

  return (
    <AccountStateContext.Provider value={initialContextValue}>
      {children}
    </AccountStateContext.Provider>
  );
}

export { AccountStateContext, AccountStateContextProvider };
