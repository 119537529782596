import styled, { css, DefaultTheme } from 'styled-components';
import { CardTypes, Statuses } from './types';
import { ReactComponent as Document } from '../../assets/icons/attachment.svg';
import { ReactComponent as File } from '../../assets/icons/fileDocument.svg';
import { ReactComponent as House } from '../../assets/icons/house.svg';
import { ReactComponent as User } from '../../assets/icons/user.svg';
import { ReactComponent as Bedrooms } from '../../assets/icons/bedrooms.svg';
import { ReactComponent as Coins } from '../../assets/icons/coins.svg';
import { ReactComponent as Search } from '../../assets/icons/search.svg';
import { ReactComponent as Info } from '../../assets/icons/info.svg';

const iconStyles = css`
  fill: ${(props) => props.theme.colorPalette.secondary.steel};
  width: 16px;
  height: 16px;
`;

const getTagStatusStyles = (theme: DefaultTheme, status: Statuses) => {
  switch (status) {
    case Statuses.ERROR:
      return `background-color: ${theme.colorPalette.secondary.red};`;
    case Statuses.SUCCESS:
      return `background-color: ${theme.colorPalette.secondary.green};`;
    case Statuses.WARNING:
      return `background-color: ${theme.colorPalette.secondary.lightInk};`;
    case Statuses.REMOVED:
      return `background-color: ${theme.hub.colorPalette.misc.nearBlack};`;
    case Statuses.SAVILLS:
      return `background-color: ${theme.colorPalette.branding.yellow}; color: ${theme.colorPalette.branding.ink}; font-weight: ${theme.fonts.secondary.fontWeightBold};`;
    case Statuses.ZOOPLA:
      return `background-color: ${theme.hub.colorPalette.propertyCards.zoopla}; color: ${theme.colorPalette.branding.white}; font-weight: ${theme.fonts.secondary.fontWeightBold};`;
    case Statuses.RIGHTMOVE:
      return `background-color: ${theme.hub.colorPalette.propertyCards.rightmove}; color: ${theme.colorPalette.branding.ink}; font-weight: ${theme.fonts.secondary.fontWeightBold};`;
    case Statuses.INFORMATION:
    default:
      return `background-color: ${theme.colorPalette.branding.ink};`;
  }
};

export const CardWrapper = styled.article<{
  cardType?: CardTypes;
  isFullWidth?: boolean;
}>`
  display: grid;
  font-family: ${(props) => props.theme.fonts.secondary.font};
  border-radius: 2px;
  background: ${(props) => props.theme.colorPalette.branding.white};
  color: ${(props) => props.theme.colorPalette.branding.ink};
  padding: 20px;
  grid-template-columns: ${(props) =>
    props.isFullWidth ? '1fr' : 'repeat(3, 1fr)'};
  max-width: 1024px;
  overflow-wrap: anywhere;

  ${(props) =>
    props.cardType === CardTypes.TASK &&
    css`
      align-content: space-between;
    `}
  @media screen and (${({ theme }) => theme.media.widthLtMed}) {
    grid-template-columns: repeat(1, 1fr);
  }
`;

export const CardBody = styled.div`
  grid-area: content;
  grid-column-start: 1;
  grid-column-end: 3;
  grid-row: 1;
`;

export const AlertCardBody = styled(CardBody)`
  grid-row: 2;
  @media screen and (${({ theme }) => theme.media.widthLtMed}) {
    grid-column-start: 1;
    grid-column-end: 4;
  }
`;

export const Sidebar = styled.div`
  grid-area: sidebar;
  grid-column-start: 3;
  grid-column-end: 4;
  grid-row: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: start;
  grid-gap: 10px;

  @media screen and (${({ theme }) => theme.media.widthLtMed}) {
    grid-column-start: 1;
    grid-column-end: 4;
  }
`;

export const TagWrapper = styled.div`
  display: flex;
  grid-gap: 10px;
  align-items: flex-start;
  font-size: ${(props) => props.theme.typography.xxsmall};
  text-transform: uppercase;
  color: ${(props) => props.theme.colorPalette.branding.white};
`;

export const Tag = styled.span<{ status: Statuses }>`
  display: inline-block;
  ${(props) => getTagStatusStyles(props.theme, props.status)};
  padding: 5px 10px;
  border-radius: 2px;
`;

export const CTAWrapper = styled.div`
  display: flex;
  grid-gap: 10px;
  align-items: flex-start;
  grid-column-start: 1;
  grid-column-end: 4;
  grid-row: 2;
  margin: 20px 0 0 0;
  flex-wrap: wrap;
`;

export const CardTitle = styled.h1<{ isMinimized?: boolean }>`
  font-family: ${(props) => props.theme.fonts.secondary.font};
  font-size: ${(props) => props.theme.typography.large};
  margin: ${(props) => (props.isMinimized ? '0 0 10px 0;' : '10px 0;')};
  font-weight: ${(props) => props.theme.fonts.secondary.fontWeightBold};
`;

export const Price = styled.h1`
  font-family: ${(props) => props.theme.fonts.secondary.font};
  font-size: ${(props) => props.theme.typography.xlarge};
  margin: 10px 0;
`;

export const CardParagraph = styled.p`
  font-size: ${(props) => props.theme.typography.small};
`;

export const CardFooterWrapper = styled.footer`
  border-top: 1px solid ${(props) => props.theme.colorPalette.forms.inputBorder};
  margin: 20px 0 0;
  padding: 20px 0 0;
  display: flex;
  grid-gap: 20px;
  font-size: ${(props) => props.theme.typography.xxsmall};
  grid-column-start: 1;
  grid-column-end: 4;
  grid-row: 2;
`;

export const FooterItem = styled.span`
  display: flex;
  align-items: center;
  grid-gap: 6px;
`;

export const DocumentsIcon = styled(Document)`
  transform: rotate(220deg);
  ${iconStyles}
  stroke: currentColor;
`;

export const FileIcon = styled(File)`
  ${iconStyles}
  stroke: none;
`;

export const HouseIcon = styled(House)`
  ${iconStyles}
  stroke: currentColor;
`;

export const UserIcon = styled(User)`
  ${iconStyles}
  stroke: currentColor;
`;

export const BedroomIcon = styled(Bedrooms)`
  ${iconStyles}
  stroke: none;
`;

export const CoinIcon = styled(Coins)`
  ${iconStyles}
  stroke: none;
`;

export const SearchIcon = styled(Search)`
  ${iconStyles}
  width: 25px;
  height: 25px;
  stroke: none;
`;

export const IconWrapper = styled.span<{ iconTheme: string }>`
  display: block;
  padding: 25px;
  background-color: ${(props) =>
    props.iconTheme === 'light'
      ? props.theme.colorPalette.branding.white
      : props.theme.colorPalette.branding.ink};
  color: ${(props) =>
    props.iconTheme === 'light'
      ? props.theme.colorPalette.branding.ink
      : props.theme.colorPalette.branding.white};
  border: 1px solid ${(props) => props.theme.colorPalette.forms.inputBorder};
  width: 80px;
  height: 80px;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
  align-items: center;
  margin: 0 0 0 20px;

  svg {
    width: 30px;
    height: 30px;
    fill: currentColor;
  }
`;

export const ContactWrapper = styled.article`
  display: flex;
  grid-gap: 16px;
`;

export const ContactBody = styled.div`
  display: flex;
  flex-direction: column;
`;

export const ContactSidebar = styled.div`
  display: none;
  @media screen and (${({ theme }) => theme.media.widthSml}) {
    display: block;
  }
`;

export const ContactTitle = styled.h1`
  font-family: ${(props) => props.theme.fonts.secondary.font};
  font-size: ${(props) => props.theme.typography.large};
  color: ${(props) => props.theme.colorPalette.branding.ink};
  margin: 2px 0 10px;
`;

export const ContactName = styled.h2`
  font-family: ${(props) => props.theme.fonts.primary.font};
  font-size: ${(props) => props.theme.typography.default};
  margin: 0 0 5px;
`;

export const ContactRole = styled.p`
  font-family: ${(props) => props.theme.fonts.secondary.font};
  font-size: ${(props) => props.theme.typography.xsmall};
  margin: 0 0 5px;
  text-transform: uppercase;
  color: ${(props) => props.theme.colorPalette.forms.inputBorder};
`;

export const ContactCTA = styled.a`
  display: inline-block;
  position: relative;
  color: ${(props) => props.theme.colorPalette.secondary.red};
  padding-right: 10px;
  margin-top: 5px;
  font-family: ${(props) => props.theme.fonts.secondary.font};
  font-size: ${(props) => props.theme.typography.xsmall};
  font-weight: ${(props) => props.theme.fonts.secondary.fontWeightMed};
  text-decoration: none;
  width: fit-content;
  text-transform: uppercase;
  background: none;
  border: none;

  &:after {
    content: ' ';
    border-style: solid;
    border-width: 5px 0 6px 8px;
    border-color: transparent transparent transparent
      ${(props) => props.theme.colorPalette.secondary.red};
    position: absolute;
    right: -5px;
    top: 1px;
  }
`;

export const InlineCTA = styled.a`
  display: inline-block;
  position: relative;
  color: ${(props) => props.theme.colorPalette.branding.ink};
  padding: 0 10px 10px 0;
  margin: 5px 0 2px;
  font-family: ${(props) => props.theme.fonts.secondary.font};
  font-size: ${(props) => props.theme.typography.xsmall};
  text-decoration: none;
  width: fit-content;
  font-weight: ${(props) => props.theme.fonts.secondary.fontWeightMed};

  &:after {
    content: ' ';
    height: 1px;
    width: 100%;
    display: block;
    background: ${(props) => props.theme.colorPalette.secondary.red};
  }
`;

export const ContactPhone = styled.p`
  font-family: ${(props) => props.theme.fonts.secondary.font};
  font-size: ${(props) => props.theme.typography.xxsmall};
  margin: 0 0 2px;
`;

export const ContactThumbnail = styled.div`
  width: 86px;
  height: 86px;
  background: ${(props) => props.theme.colorPalette.secondary.lightInk};
`;

export const Tagline = styled.p`
  text-transform: uppercase;
  font-size: ${(props) => props.theme.typography.small};
`;

export const Headline = styled.h1`
  font-family: ${(props) => props.theme.fonts.primary};
  font-size: ${(props) => props.theme.typography.xlarge};
  padding-bottom: 20px;
  border-bottom: 1px solid
    ${(props) => props.theme.colorPalette.forms.inputBorder};

  @media screen and (${({ theme }) => theme.media.widthLtMed}) {
    font-size: ${(props) => props.theme.typography.large};
  }
`;

export const ItemList = styled.ul`
  display: flex;
  width: 100%;
  grid-gap: 10px;
  justify-content: space-between;
  @media screen and (${({ theme }) => theme.media.widthLtMed}) {
    flex-direction: column;
  }
`;

export const Item = styled.li`
  display: flex;
  flex-direction: column;
  min-width: 175px;
  padding: 0 10px 0 0;
  word-break: break-all;
`;

export const ItemHeader = styled.span`
  font-size: ${(props) => props.theme.typography.xsmall};
  width: 100%;
  display: block;
  font-weight: ${(props) => props.theme.fonts.secondary.fontWeightBold};
  margin: 0 0 10px;
`;
export const ItemBody = styled.span`
  font-size: ${(props) => props.theme.typography.small};
  width: 100%;
  display: block;
  font-weight: ${(props) => props.theme.fonts.secondary.fontWeightMed};
  color: ${(props) => props.theme.colorPalette.secondary.steel};
  word-break: break-word;

  svg {
    position: relative;
    top: 2px;
    margin: 0 5px 0 0;
  }
`;

export const MapWrapper = styled.div`
  grid-area: sidebar;
  grid-column-start: 3;
  grid-column-end: 4;
  grid-row: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: start;
  grid-gap: 10px;
  overflow: hidden;
  margin: 0 0 0 30px;
  @media screen and (${({ theme }) => theme.media.widthLtMed}) {
    grid-column-start: 1;
    grid-column-end: 3;
    grid-row: 1;
    margin: 40px 0 0;
    align-items: flex-start;
  }

  img {
    object-fit: contain;
    width: 100%;
    margin: 0 10px 0 20px;
    @media screen and (${({ theme }) => theme.media.widthLtMed}) {
      margin: 0 0 30px;
    }
  }
`;

export const AlertWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  margin: 40px 0 0;
  align-items: center;
  @media screen and (${({ theme }) => theme.media.widthLtMed}) {
    padding-top: 30px;
    border-top: 1px solid
      ${(props) => props.theme.colorPalette.forms.inputBorder};
    flex-direction: column;
    align-items: flex-start;
    grid-gap: 30px;
  }
`;

export const ToggleWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  grid-gap: 10px;
`;

export const Wrapper = styled.div`
  background: ${(props) => props.theme.colorPalette.branding.white};
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  min-height: 160px;
  min-width: 160px;
  width: 100%;
  color: ${(props) => props.theme.colorPalette.branding.ink};
  padding: 15px;
  text-align: center;

  span {
    font-family: ${(props) => props.theme.fonts.primary.font};
    font-weight: ${(props) => props.theme.fonts.primary.fontWeight};
    font-size: ${(props) => props.theme.typography.xxlarge};
    word-break: break-all;

    @media screen and (${(props) => props.theme.media.widthMed}) {
      font-size: 48px;
    }
  }

  h4 {
    font-family: ${(props) => props.theme.fonts.secondary.font};
    font-weight: ${(props) => props.theme.fonts.secondary.fontWeight};
    font-size: ${(props) => props.theme.typography.small};
    margin: 0;
  }
`;

export const TitleContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 5px;
`;

export const InfoIcon = styled(Info)`
  width: 18px;
  height: 18px;
  fill: currentColor;
`;

export const ErrorMessage = styled.p`
  font-family: ${(props) => props.theme.fonts.secondary.font};
  font-weight: ${(props) => props.theme.fonts.secondary.fontWeight};
  font-size: ${(props) => props.theme.typography.xsmall};
`;

export const OfferContact = styled.a``;
