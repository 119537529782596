import { lighten } from 'polished';
import styled from 'styled-components';

export const CardWrapper = styled.div<{
  floating?: boolean;
  floatingPosition?: string;
  isExpanded: boolean;
}>`
  background: ${(props) => props.theme.colorPalette.branding.ink};
  width: ${(props) => (props.isExpanded ? '260px' : '76px')};
  cursor: pointer;

  ${(props) =>
    props.floating &&
    `
      z-index: 900;
      position: fixed;
      right: 20px;
      bottom: ${props.floatingPosition ?? '50px'};
  `}

  ${(props) =>
    !props.isExpanded &&
    props.floating &&
    `
    &::after {
      content: '';
      border-style: solid;
      border-width: 12px 10px 0 10px;
      border-color: ${props.theme.colorPalette.branding.ink} transparent transparent transparent;
      position: absolute;
      top: 100%;
      left: 50%;
      transform: translateX(-50%);
    }
  `}
`;

export const SmallNegCardWrapper = styled.div<{ isAgentCard?: boolean }>`
  background: ${(props) => props.theme.colorPalette.branding.white};
  border: 1px solid ${(props) => props.theme.colorPalette.forms.inputBorder};
  border-radius: ${(props) => (props.isAgentCard ? '3px' : '0')};
  padding: ${(props) => (props.isAgentCard ? '20px' : '10px')};
  box-shadow: ${(props) =>
    props.isAgentCard
      ? `0 0 3px ${lighten(
          0.4,
          props.theme.hub.colorPalette.misc.lightGreyBlue,
        )}`
      : `0`};
`;

export const SmallNegCardDetailsWrapper = styled.div`
  display: flex;
  column-gap: 10px;
`;

export const SmallNegCardTitle = styled.h2`
  color: ${(props) => props.theme.hub.colorPalette.font.ink};
  font-size: ${(props) => props.theme.typography.default};
  font-family: ${(props) => props.theme.fonts.primary.font};
  font-weight: ${(props) => props.theme.fonts.primary.fontWeight};
  margin: 0 0 10px;
`;

export const AgentImageContainer = styled.div<{ isExpanded: boolean }>`
  position: relative;
  height: ${(props) => (props.isExpanded ? '260px' : '76px')};
`;

export const AgentImage = styled.img<{ isExpanded: boolean }>`
  width: ${(props) => (props.isExpanded ? '260px' : '60px')};
  height: ${(props) => (props.isExpanded ? '260px' : '60px')};
  object-fit: cover;
  opacity: ${(props) => (props.isExpanded ? '1' : '0')};
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
`;

export const AgentImageSmall = styled(AgentImage)<{ isExpanded: boolean }>`
  width: ${(props) => (props.isExpanded ? '216px' : '60px')};
  height: ${(props) => (props.isExpanded ? '216px' : '60px')};
  opacity: ${(props) => (props.isExpanded ? '0' : '1')};
`;

export const AgentName = styled.h3`
  font-family: ${(props) => props.theme.fonts.primary.font};
  font-size: ${(props) => props.theme.typography.large};
  font-weight: ${(props) => props.theme.fonts.primary.fontWeight};
`;

export const SmallNegCardAgentName = styled(AgentName)`
  margin: 0;
  font-size: ${(props) => props.theme.typography.small};
  font-family: ${(props) => props.theme.fonts.primaryAlt.font};
  font-weight: ${(props) => props.theme.fonts.secondary.fontWeight};
`;

export const AgentDetails = styled.div`
  padding: 15px;
`;

export const AgentDetailsList = styled.ul<{ color?: string }>`
  color: ${(props) =>
    props.color ? props.color : props.theme.colorPalette.branding.white};
  font-size: ${(props) => props.theme.typography.small};
`;

export const AgentDetailsListItem = styled.li<{
  fontSize?: string;
  margin?: string;
  isBold?: boolean;
}>`
  font-family: ${(props) => props.theme.fonts.secondary.font};
  font-weight: ${(props) =>
    props.isBold
      ? props.theme.fonts.secondary.fontWeightMed
      : props.theme.fonts.secondary.fontWeight};
  font-size: ${(props) =>
    props.fontSize ? props.fontSize : props.theme.typography.xsmall};
  margin-bottom: ${(props) => (props.margin ? props.margin : '10px')};
  white-space: nowrap;
`;

export const AgentDetailsListItemWithWrap = styled(AgentDetailsListItem)`
  word-wrap: break-word;
  overflow-wrap: anywhere;
`;

export const ContactLink = styled.a`
  color: ${(props) => props.theme.colorPalette.branding.yellow};
  font-weight: ${(props) => props.theme.fonts.secondary.fontWeightMed};
  padding-right: 16px;
  position: relative;
  text-decoration: none;
  text-transform: uppercase;
  display: inline-block;
  margin: 15px 0 20px;

  &:hover,
  &:focus {
    text-decoration: underline;
  }

  &::after {
    content: '';
    position: absolute;
    width: 0;
    height: 0;
    top: 50%;
    transform: translateY(-50%);
    right: 0;
    border-style: solid;
    border-width: 6px 0 6px 8px;
    border-color: transparent transparent transparent
      ${(props) => props.theme.colorPalette.branding.yellow};
  }
`;

export const MarketContactLink = styled.a`
  background: ${(props) => props.theme.colorPalette.branding.yellow};
  color: ${(props) => props.theme.colorPalette.branding.ink};
  text-decoration: none;
  padding: 10px;
  font-family: ${(props) => props.theme.fonts.secondary.font};
  font-weight: ${(props) => props.theme.fonts.secondary.fontWeight};
  font-size: ${(props) => props.theme.typography.small};
  border: none;

  &:focus,
  &:hover {
    text-decoration: underline;
  }
`;

export const MarketContactContainer = styled.div`
  flex-shrink: 0;
`;

export const MarketAppraisalCardWrapper = styled.div`
  background: ${(props) => props.theme.colorPalette.branding.ink};
  width: 100%;
  display: flex;
`;

export const MarketAppraisalImageContainer = styled.div`
  width: 86px;
  height: 86px;
`;

export const MarketAppraisalCardDetails = styled.div`
  color: ${(props) => props.theme.colorPalette.branding.white};
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex: 1;
  padding: 5px;
  gap: 5px;

  @media screen and (${(props) => props.theme.media.widthSml}) {
    padding: 15px;
  }
`;

export const MarketAppraisalContactName = styled.li`
  font-size: ${(props) => props.theme.typography.default};
  font-family: ${(props) => props.theme.fonts.primary.font};
  font-weight: ${(props) => props.theme.fonts.primary.fontWeightMed};
  margin-bottom: 5px;
  display: inline-block;
  word-break: break-all;

  @media screen and (${(props) => props.theme.media.widthSml}) {
    font-size: ${(props) => props.theme.typography.large};
  }
`;

export const MarketAppraisalContactNumber = styled.li`
  font-size: ${(props) => props.theme.typography.xxsmall};
  font-family: ${(props) => props.theme.fonts.secondary.font};
  font-weight: ${(props) => props.theme.fonts.secondary.fontWeight};
`;

export const ButtonWrapper = styled.div`
  margin: 10px 0 0;
`;
