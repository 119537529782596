import React, { useEffect } from 'react';

import { useCookieModal } from 'hooks/useCookieModal';
import { FooterWrapper } from './Footer.styled';
import CookieModal from './CookieModal';
import { FooterProps } from './types';

const Footer: React.FC<FooterProps> = ({ footerHeaderMarkup }) => {
  const { loadEventHandlers } = useCookieModal();
  useEffect(() => {
    if (footerHeaderMarkup?.footerContent) {
      loadEventHandlers();
    }
  }, [footerHeaderMarkup, loadEventHandlers]);

  if (
    !footerHeaderMarkup?.footerContent ||
    !footerHeaderMarkup?.headerContent
  ) {
    return null;
  }

  return (
    <>
      <CookieModal modalHtml={footerHeaderMarkup.headerContent} />
      <FooterWrapper
        dangerouslySetInnerHTML={{
          __html: footerHeaderMarkup.footerContent,
        }}
      />
    </>
  );
};

export default Footer;
