import styled, { css } from 'styled-components';
import { transparentize, hideVisually, lighten } from 'polished';

import { ReactComponent as Map } from 'assets/icons/map.svg';
import { ReactComponent as Floorplan } from 'assets/icons/floorplan.svg';
import { ReactComponent as Gallery } from 'assets/icons/gallery.svg';
import { withNoMouseFocus } from 'utils/helpers/hoc/preventFocusOnClick';
import { attributeIconStyles } from './Attributes/Attributes.styled';

import {
  PROPERTY_CARD_IMAGE_HEIGHT,
  GALLERY_PROPERTY_IMAGE_HEIGHT,
  PROPERTY_CARD_DIMENSIONS,
  PropertyCardType,
} from './constants';

export const PropertyCardHeadContainer = styled.div<{
  isMiniCard?: boolean;
}>`
  min-height: ${(props) =>
    props.isMiniCard ? 'auto' : PROPERTY_CARD_IMAGE_HEIGHT.smallViewport};
  height: ${(props) =>
    props.isMiniCard
      ? PROPERTY_CARD_IMAGE_HEIGHT.miniCardHeight
      : PROPERTY_CARD_DIMENSIONS.maxHeight};
  width: 100%;
  min-width: 0;
  position: relative;
  display: block;

  @media screen and (${(props) => props.theme.media.widthLtMed}) {
    height: 300px;
  }
`;

export const CardWrapper = styled.div<{ isMiniCard?: boolean }>`
  display: grid;
  grid-template-rows: auto 1fr;
  grid-template-columns: 1fr;
  min-height: ${(props) =>
    props.isMiniCard ? PROPERTY_CARD_DIMENSIONS.miniCardHeight : 'none'};
  max-width: ${(props) =>
    props.isMiniCard ? PROPERTY_CARD_DIMENSIONS.miniCardMaxWidth : 'none'};
  margin: 0 auto;

  ${(props) =>
    !props.isMiniCard &&
    `
    @media screen and (${props.theme.media.widthMed}) {
      grid-template-rows: 1fr;
      grid-template-columns: 1fr 1fr;
    }
  `}
`;

const getPropertyInfoFlexDirection = (isCommercialOrFarm?: boolean) => {
  if (isCommercialOrFarm) {
    return 'column-reverse';
  }
  return 'column';
};

export const AgentWrapper = styled.div`
  display: flex;
  margin-top: auto;
  padding: 0 30px;
  margin-bottom: 20px;
  flex-wrap: wrap;
  height: 70px;
  overflow: hidden;
  justify-content: flex-start;
  gap: 20px;
`;

export const PropertyInfoContainer = styled.div<{
  isLetting?: boolean;
  isCommercialOrFarm?: boolean;
}>`
  display: flex;
  flex-direction: ${(props) =>
    getPropertyInfoFlexDirection(props.isCommercialOrFarm)};
  padding: 15px;
`;

export const MainDetails = styled.div<{ type?: PropertyCardType }>`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: relative;
  overflow: hidden;
  text-decoration: none;

  ${(props) =>
    props.type === PropertyCardType.SAVED_PROPERTY &&
    `
    ${PropertyInfoContainer} {
      padding: 30px;
    }
  `}

  ${(props) =>
    props.type === PropertyCardType.VIEWING &&
    `
    height: 100%;

    > div:first-child {
      height: 100%;

      ${AgentWrapper} {
        ul {
        padding-top: 10px;
      }
    }
      ul:first-child {
        padding: 15px 0px 0px;
      }

      div {
        padding-left: 0;
      }
    }
  `}
`;

export const MainDetailsLink = styled.a`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
`;

export const sharePropertyFeatureStyles = css`
  display: flex;
  padding: 12px 16px;
  justify-content: space-between;
  gap: 10px;
  flex-wrap: wrap;

  @media screen and (${(props) => props.theme.media.widthMed}) {
    padding: 12px;
    margin: 0;
  }
`;

export const PropertyFeatures = styled.div<{
  type?: PropertyCardType;
  isParent?: boolean;
  wrap?: boolean;
}>`
  ${sharePropertyFeatureStyles};
  background: ${(props) =>
    props.isParent
      ? lighten(0.6, props.theme.colorPalette.secondary.lightInk)
      : props.theme.colorPalette.branding.white};
  padding: 10px 16px;
  margin: 0;
  border-top: 1px solid ${(props) => props.theme.colorPalette.forms.inputBorder};
  align-items: center;
  flex-wrap: ${(props) => (props.wrap ? 'wrap' : 'nowrap')};
  overflow: hidden;

  ${(props) =>
    props.type === PropertyCardType.VIEWING &&
    `
      @media screen and (${props.theme.media.widthMed}) {
    padding: 14px 20px;
  }
  `}

  ${(props) =>
    props.isParent &&
    `
    background-color: ${props.theme.hub.colorPalette.misc.lightGrey};
    border-color: ${props.theme.hub.colorPalette.misc.lightGrey};
    `}
`;

export const PropertyOverlay = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 10px;
  flex-wrap: wrap;
  background-color: rgba(0, 0, 0, 0.36);
  border-top: 1px solid ${(props) => props.theme.colorPalette.forms.inputBorder};
  color: ${(props) => props.theme.hub.colorPalette.primary.white};
  align-items: center;
  position: absolute;
  bottom: 0;
  left: 0;
  margin: 0;
  padding: 11px 15px;
  width: 100%;
  z-index: 1;

  button span {
    color: ${(props) => props.theme.hub.colorPalette.primary.white};
  }
`;

export const SizeContainer = styled.div`
  display: flex;
  justify-content: space-between;
  overflow: hidden;
`;

export const PropertyExtrasActionsContainer = styled.div`
  position: absolute;
  z-index: 1;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 48px;
  color: ${(props) => props.theme.colorPalette.branding.white};
  background: ${(props) =>
    transparentize(0.5, props.theme.colorPalette.branding.ink)};
  display: flex;
  align-items: center;
  padding: 5px 15px;
`;

const ExtrasButtonBase = styled.button`
  background: ${(props) => props.theme.colorPalette.branding.white};
  border: 0;
  outline: 0;
  font-family: ${(props) => props.theme.fonts.secondary.font};
  font-size: ${(props) => props.theme.typography.small};
  color: ${(props) => props.theme.colorPalette.branding.ink};
  padding: 6px 8px 6px 28px;
  margin-right: 10px;
  text-transform: uppercase;
  position: relative;
  height: 30px;

  &:focus {
    background: ${(props) => props.theme.colorPalette.secondary.steel};
    color: ${(props) => props.theme.colorPalette.branding.white};
  }

  @media screen and (${(props) => props.theme.media.widthXlrg}) {
    padding-left: 34px;
  }
`;

export const PropertyExtrasButton = withNoMouseFocus(ExtrasButtonBase);

const ActionIconStyles = css`
  width: 20px;
  height: 20px;
  fill: currentColor;
  position: absolute;
  left: 8px;
  top: 50%;
  transform: translateY(-50%);
`;

export const ButtonTitle = styled.span`
  @media screen and (${(props) => props.theme.media.widthLtSml}) {
    ${hideVisually};
  }

  @media screen and (${(props) =>
    `${props.theme.media.widthMed}) and (${props.theme.media.widthLtXlrg})`}  {
    ${hideVisually};
  }
`;

export const MapIcon = styled(Map)`
  ${ActionIconStyles};
`;

export const FloorplanIcon = styled(Floorplan)`
  ${ActionIconStyles};
`;

export const GalleryIcon = styled(Gallery)`
  ${ActionIconStyles};
`;

export const linkChevronStyles = css`
  content: '';
  position: absolute;
  width: 0;
  height: 0;
  top: 50%;
  transform: translateY(-50%);
  right: 0;
  border-style: solid;
  border-width: 6px 0 6px 8px;
  border-color: transparent transparent transparent
    ${(props) => props.theme.colorPalette.secondary.red};
`;

export const PropertyTypeContainer = styled.div`
  display: none;

  @media screen and (${(props) => `${props.theme.media.widthSml})`} {
    display: block;
    white-space: nowrap;
    overflow: hidden;
  }
`;

export const MapContainer = styled.div`
  height: calc(100vh - 160px);
  width: 100vw;

  @media screen and (${(props) => `${props.theme.media.widthSml})`} {
    width: calc(100vw - 160px);
  }
`;

export const GalleryContainer = styled.div`
  width: 100vw;

  @media screen and (${(props) => `${props.theme.media.widthMed})`} {
    width: calc(100vw - 160px);
    max-width: 1024px;
  }
`;

export const GalleryImageContainer = styled.div`
  overflow: hidden;
  height: ${GALLERY_PROPERTY_IMAGE_HEIGHT.smallViewport};
  max-height: 100vh;
  padding-bottom: 36px;

  img {
    object-fit: contain;
    max-height: 100%;
  }

  @media screen and (${(props) => `${props.theme.media.widthSml})`} {
    height: ${GALLERY_PROPERTY_IMAGE_HEIGHT.medViewport};
    padding-bottom: 0;
  }

  @media screen and (${(props) => `${props.theme.media.widthMed})`} {
    height: 80vh;
    max-height: ${GALLERY_PROPERTY_IMAGE_HEIGHT.largeViewport};
  }
`;

export const PropertyImage = styled.img`
  width: 100%;
`;

export const PropertyCardActions = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 15px;
`;

export const CarouselIndicator = styled.div`
  padding-left: 26px;
  margin-top: 2px;
  font-size: ${(props) => props.theme.typography.small};
  font-family: ${(props) => props.theme.fonts.secondary.font};
  font-weight: ${(props) => props.theme.fonts.secondary.fontWeight};
  position: relative;
`;

export const SearchAgentWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 0 20px;
`;

export const CarouselIndicatorIcon = styled(Gallery)`
  ${attributeIconStyles};
`;

export const PropertyCardTagWrapper = styled.div`
  margin: 0 0 10px;
  display: flex;
  align-items: center;
  gap: 5px;
  flex-wrap: wrap;
`;

export const PropertyCardTitle = styled.span`
  color: ${(props) => props.theme.colorPalette.branding.ink};
  font-family: ${(props) => props.theme.fonts.secondary.font};
  font-size: ${(props) => props.theme.typography.xsmall};
  font-weight: ${(props) => props.theme.fonts.secondary.fontWeight};
`;

export const CardTagWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 5px;
  font-weight: ${(props) => props.theme.fonts.secondary.fontWeightMed};
`;
