import { Address as AddressModel } from 'types/property';

export const getAddressLine = (address: AddressModel) => {
  if (address.buildingName && address.buildingNumber) {
    return `${address.buildingName}, ${address.buildingNumber} ${address.line1}`;
  }
  if (address.buildingNumber) {
    return `${address.buildingNumber} ${address.line1}`;
  }
  if (address.buildingName) {
    return `${address.buildingName} ${address.line1}`;
  }
  return `${address.line1}`;
};
