import styled from 'styled-components';

export const CookieModalWrapper = styled.div`
.sv-cookie-management__banner {
    opacity: 0;
    display: none;
    bottom: 1.25rem;
    position: fixed;
    width: 100%;
    padding: 0 .625rem;
    z-index: 100;
    transition: opacity .5s ease-out
}

.sv-cookie-management__banner.sv--visible {
    opacity: 1
}

.sv-cookie-management__banner-content {
    max-width: 78rem;
    margin: 0 auto;
    padding: .75rem;
    background: #181a2c;
    border-radius: .25rem;
    font-size: .875rem;
    position: relative
}

.sv-cookie-management__banner-cta {
    text-align: center;
    align-self: flex-end
}

.sv-cookie-management__banner-cta>button {
    margin: 0
}

.sv-cookie-management,.sv-cookie-management__warning,.sv-cookie-management__warning-link {
    font-size: .875rem;
    font-family: DomineWebRegular,Georgia,Palatino Linotype,serif
}

.sv-cookie-management__warning {
    color: #fff;
    margin-bottom: 1.25rem;
    display: block
}

.sv-cookie-management__management-link span,.sv-cookie-management__warning-link {
    color: #ffdf00;
    display: inline;
    border-bottom: 1px solid #ffdf00
}

.sv-cookie-management__management-link {
    font-size: .875rem;
    font-family: Gotham,sans-serif;
    font-weight: 700;
    margin: 0 auto 1.5rem;
    display: inline-block
}

.sv-cookie-management__modal {
    display: none;
    background: rgba(73,75,88,.50196);
    height: 100%;
    left: 0;
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 1002;
    overflow-y: scroll
}

.sv-cookie-management__modal--visible {
    display: block
}

.sv-cookie-management__modal-title {
    font-family: DomineWebRegular,Georgia,Palatino Linotype,serif;
    font-size: 1.5rem
}

.sv-cookie-management__modal-link {
    color: #000;
    border-bottom: 1px solid #000;
    font-weight: 500;
    text-decoration: none;
}

.sv-cookie-management__modal-cta {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    grid-gap: 1rem;
    gap: 1rem;
    justify-content: flex-end;
    margin-top: 1rem;
    align-self: flex-end;
    margin-top: auto;

}

.sv-cookie-management__modal-cta>button {
    margin-bottom: auto;
    width: auto
}

.sv-cookie-management__modal-content {
    display: flex;
    flex-direction: column;
    color: #000;
    font-size: .875rem;
    font-family: DomineWebRegular,Georgia,Palatino Linotype,serif;
    background-color: #fff;
    width: 100vw;
    height: 100vh;
    left: 0;
    right: 0;
    margin: 0 auto;
    padding: 28px 28px 56px;
    overflow-y: scroll
}

.sv-cookie-management__modal .sv-custom-input {
    margin: 1.875rem 0 .625rem;
    font-weight: 500
}

.sv-cookie-management__modal .sv-expandable-list {
    margin-bottom: 1.5rem
}

.sv-cookie-management__modal .sv-expandable-item {
    border-top: none
}

.sv-cookie-management__modal .sv-expandable-item__header {
    padding: 1.25rem 0
}

.sv-cookie-management__modal .sv-expandable-item__header:before {
    height: 1.1875rem;
    width: 1.1875rem
}

.sv-cookie-management__modal .sv-expandable-item__title {
    font-size: 1.125rem;
    line-height: 1.2
}

.sv-cookie-management__modal .sv-expandable-item__content p {
    font-size: .875rem
}

.sv-header__wrap { display: none; }





    .sv-cookie-management__banner-content {
        display: flex;
        align-items: center;
        margin: 0 auto
    }

    .sv-cookie-management__banner-cta {
        margin-left: 1.25rem
    }

    .sv-cookie-management__banner-cta>button {
        margin-bottom: 0
    }

    .sv-cookie-management__warning {
        flex: 1;
        margin-bottom: 0
    }

    .sv-cookie-management__management-link {
        margin-bottom: 1.125rem;
        display: block
    }

    @media (min-width: 1024px) {
      .sv-cookie-management__modal-content {
          width: 35.9375rem;
          height: auto;
          max-height: 85%;
          position: relative;
      }
  }

    .sv-expandable-item__header {
        padding: 1.1875rem .5rem 1.3125rem 0
    }

    .sv-expandable-item__header:before {
        width: 1.5rem;
        height: 1.5rem;
        top: 1.375rem;
        right: .5rem
    }

    .sv-expandable-item__title {
        font-size: 1.5rem;
        line-height: 1.2
    }



.sv-button {
    -webkit-tap-highlight-color: transparent;
    background-color: transparent;
    border: 0;
    border-radius: 0;
    color: #252839;
    display: inline-block;
    font-family: Gotham,sans-serif;
    font-weight: 500;
    font-size: .875rem;
    line-height: 1;
    margin: 0 0 1rem;
    padding: 1.125rem 2.5rem;
    position: relative;
    text-align: center;
    width: 100%;
    -webkit-user-select: none;
    -moz-user-select: none;
    user-select: none;
    background-image: linear-gradient(90deg,#252839 50%,#ffdf00 0);
    background-size: 200% auto;
    background-position: 100% 0;
    background-repeat: no-repeat
}

.sv-button:active {
    background-position: -.8125rem 0;
    color: #ffdf00
}

.sv-button.sv--disabled,.sv-button:disabled {
    background: #eee!important;
    color: #78828b!important;
    cursor: default;
    pointer-events: none
}

.sv-button.sv--disabled>a {
    color: #78828b!important
}



  .show-cookie-modal.sv-cookie-management__modal {
    @media screen and (${(props) => props.theme.media.widthLtSml}) {
      background: ${(props) => props.theme.colorPalette.branding.white};
      padding-bottom: 80px;
    }

    .sv-cookie-management__modal-title {
      margin-top: 0;
      margin-bottom: 30px;
        font-weight: 300;
    }

    overflow: hidden;
    position: fixed;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;


    p {
        margin-top: 0;
        line-height: 20px;
        margin: 0 0 16px;
        font-weight: 300;
      }
    .sv-expandable-item__title {
      font-size: 18px;
    }
    .sv-expandable-item__content { display: none; }

    .sv-expandable-item__title-active {

    .sv-expandable-item__content { display: block; padding-bottom: 6px;
      p {
        font-size: 14px;
      }
    }
    }
  }



.sv-expandable-item {
    position: relative;
    border-top: 0;
    border-bottom: 1px solid #999795
}

.sv-expandable-item__header {
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    user-select: none;
    padding: 17px 0;
}

.sv-expandable-item__header:before {
    content: "";
    position: absolute;
    width: 19px;
    height: 19px;
    top: 19px;
    right: .5rem;
    background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24.9 24.9'%3E%3Ccircle cx='12.4' cy='12.4' r='12.4' fill='%23CE1A20' class='st0'/%3E%3Cpath fill='%23FFF' d='M18.7 11.4h-5.3V6.2c0-.6-.4-1-1-1s-1 .4-1 1v5.3H6.2c-.6 0-1 .4-1 1s.4 1 1 1h5.3v5.3c0 .6.4 1 1 1s1-.4 1-1v-5.3h5.3c.6 0 1-.4 1-1s-.5-1.1-1.1-1.1z' class='st1'/%3E%3C/svg%3E")
}

.sv-expandable-item__header.sv--expanded:before {
    background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24.9 24.9'%3E%3Ccircle cx='12.4' cy='12.4' r='12.4' fill='%23CE1A20' class='st0'/%3E%3Cpath fill='%23FFF' d='M18.7 13.4H6.2c-.6 0-1-.4-1-1s.4-1 1-1h12.5c.6 0 1 .4 1 1s-.4 1-1 1z' class='st1'/%3E%3C/svg%3E")
}

.sv-expandable-item__content,.sv-expandable-item__title {
    margin: 0 3.125rem 0 0
}

.sv-expandable-item__title {
    font-family: DomineWebRegular,Georgia,Palatino Linotype,serif;
    font-weight: 400;
    font-size: 18px;
    line-height: 1.5;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap
}

.sv-expandable-item__content {
    padding-bottom: 1.5rem
}

.sv-expandable-item__content[aria-expanded=false] {
    display: none
}

.sv-expandable-item__content[aria-expanded=true] {
    display: block
}

.sv-expandable-item__content figure {
    margin-left: 0;
    margin-bottom: 3rem
}

.sv-expandable-item__content p {
    font-size: .75rem
}

.sv-expandable-item__content h3,.sv-expandable-item__content h4 {
    font-family: Gotham,sans-serif;
    font-weight: 500;
    text-transform: uppercase
}

.sv-expandable-item__content h3 {
    font-size: 1rem;
    line-height: 1.25
}

.sv-custom-input__check {
    min-height: 1.25rem;
    position: relative;
    padding-left: 1.875rem;
    line-height: 1.5;
    display: block;
    cursor: pointer;
}

.sv-custom-input__check:after,.sv-custom-input__check:before {
    height: 1.25rem;
    position: absolute;
    top: 0;
    left: 0;
    transition: all .15s;
    width: 1.25rem
}

.sv-custom-input__check:before {
    content: "";
    border: 1px solid #9a9896;
    display: inline-block
    box-sizing: border-box;
    width: 17px;
    height: 17px;
}


input:checked~.sv-custom-input__check:before {
    border: 1px solid #9a9896;
}


.sv-custom-input__check:after {
    content: "";
    background-color: #3e3e40;
    transform: scale(0)
}


.sv-custom-input.sv--checkbox input:checked~.sv-custom-input__check:after {
    transform: scale(1)
}

.sv--checkbox input {
  &:focus {
    outline: 0;
  }
}

.sv-custom-input.sv--checkbox .sv-custom-input__check:after {
    background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg width='1792' height='1792' viewBox='0 0 1792 1792' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath style='fill:%23fff' fill='%23252839' d='M1671 566q0 40-28 68l-724 724-136 136q-28 28-68 28t-68-28l-136-136-362-362q-28-28-28-68t28-68l136-136q28-28 68-28t68 28l294 295 656-657q28-28 68-28t68 28l136 136q28 28 28 68z'/%3E%3C/svg%3E");
    background-position: 50%;
    background-repeat: no-repeat;
    background-size: .875rem
}

.sv-custom-input__check.sv--sml {
    font-size: .875rem;
    min-height: 1rem;
    padding-left: 1.5rem
}

.sv-custom-input__check.sv--sml:before {
    border-width: 1px
}

.sv-custom-input__check.sv--sml:after,.sv-custom-input__check.sv--sml:before {
    height: 1rem;
    top: .125rem;
    width: 1rem
}

.sv-custom-input.sv--checkbox,.sv-custom-input.sv--radio {
    -webkit-user-select: none;
    -moz-user-select: none;
    user-select: none;
    margin-bottom: 2rem;
    display: block
}

.sv-custom-input.sv--checkbox input,.sv-custom-input.sv--radio input {
    opacity: 0;
    position: absolute
}

.sv-custom-input.sv--checkbox input:disabled~.sv-custom-input__check,.sv-custom-input.sv--radio input:disabled~.sv-custom-input__check {
    opacity: .3;
    cursor: not-allowed
}

`;
