/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable no-console */
import React, { Suspense, lazy } from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { createRoot } from 'react-dom/client';
import { DefaultTheme, ThemeProvider } from 'styled-components';
import { getFontVariations, LoadFont } from 'savills-ui-kit';
import { FeatureFlagProvider } from 'providers/FeatureFlags/FeatureFlagContextProvider';

import { ROUTES } from 'constants/routes';
import styledTheme from 'styled/theme';
import Loading from 'components/Loading/Loading';
import {
  ApolloContextProvider,
  SecureApolloContextProvider,
} from 'providers/Apollo/ApolloContextProvider';
import FatalErrorHandlerProvider from 'providers/FatalError/FatalErrorHandler';
import GlobalStyle from 'styled/createGlobalStyle';
import App from 'App';
import { getLocalizationParametersFromCookieOrUrl } from 'utils/helpers/localization/localization';
import { T9nProvider } from 'providers/T9nProvider/t9nContext';
import { LoginTracker } from 'components/Tracking/LoginTracker';
import { PageTitleProvider } from 'providers/PageTitle/PageTitleContext';
import { GoogleAnalyticsProvider } from 'providers/GoogleAnalytics/GoogleAnalyticsContext';
import { AuthProvider, AuthProviderProps } from 'react-oidc-context';
import ForbiddenErrorPage from 'views/Error/ForbiddenErrorPage';
import { decodeJwt } from 'utils/helpers/jwt/jwt';
import MaintenancePageWrapper from 'views/Notice/MaintenancePage';
import { LoggedOut } from 'views/Notice/LoggedOut';
import reportWebVitals from './reportWebVitals';

const BookingPage = lazy(() => import('views/Booking/Booking'));
const EmailsUnsubscribe = lazy(
  () => import('views/EmailsUnsubscribe/EmailsUnsubscribe'),
);

const container = document.getElementById('root') as HTMLElement;
const root = createRoot(container);

const { countryCode, languageCode, localizedRoute } =
  getLocalizationParametersFromCookieOrUrl();

const fontVariations = getFontVariations(languageCode);
const theme: DefaultTheme = {
  ...styledTheme,
  fonts: fontVariations,
};

const baseName = `${process.env.REACT_APP_BASENAME ?? '/'}${localizedRoute}`;

const url = new URL(window.location.href);
const isPreview = !!url.searchParams.get('isPreview');
const impersonationToken = url.searchParams.get('token');

if (isPreview && impersonationToken) {
  const decodedToken = decodeJwt(impersonationToken);
  const token = JSON.stringify({
    access_token: impersonationToken,
    profile: decodedToken,
  });
  sessionStorage.setItem(
    `oidc.user:${window.appConfig.authServerUri}:savills-web`,
    token,
  );
}

url.searchParams.delete('code');
url.searchParams.delete('state');
url.searchParams.delete('iss');
url.searchParams.delete('token');
url.searchParams.delete('isPreview');

const authServerBaseUri = window.appConfig.authServerUri;

const oidcConfig: AuthProviderProps = {
  authority: authServerBaseUri,
  client_id: 'savills-web',
  redirect_uri: url.toString(),
  extraQueryParams: {
    culture: `${languageCode}-${countryCode}`,
    isPreview: false,
  },
  scope: 'openid profile email offline_access',
  response_type: 'code',
  response_mode: 'query',
  automaticSilentRenew: true,
  silent_redirect_uri: window.location.origin,
  refreshTokenAllowedScope: 'openid profile email offline_access',
  loadUserInfo: true,
  metadata: {
    issuer: `${authServerBaseUri}`,
    authorization_endpoint: `${authServerBaseUri}/connect/authorize`,
    token_endpoint: `${authServerBaseUri}/connect/token`,
    userinfo_endpoint: `${authServerBaseUri}/connect/userinfo`,
    end_session_endpoint: `${authServerBaseUri}/connect/logout`,
    jwks_uri: `${authServerBaseUri}/.well-known/jwks`,
  },
};

root.render(
  <ThemeProvider theme={theme}>
    <GoogleAnalyticsProvider>
      <PageTitleProvider>
        <GlobalStyle />
        <LoadFont languageCode={languageCode} />
        <BrowserRouter basename={baseName}>
          <FatalErrorHandlerProvider>
            <Suspense fallback={<Loading />}>
              <Routes>
                <Route
                  path={ROUTES.LOGGED_OUT}
                  element={
                    <ApolloContextProvider
                      countryCode={countryCode}
                      languageCode={languageCode}
                    >
                      <T9nProvider
                        countryCode={countryCode}
                        languageCode={languageCode}
                      >
                        <LoggedOut />
                      </T9nProvider>
                    </ApolloContextProvider>
                  }
                />
                <Route
                  path={ROUTES.BOOKING}
                  element={
                    <ApolloContextProvider
                      countryCode={countryCode}
                      languageCode={languageCode}
                    >
                      <T9nProvider
                        countryCode={countryCode}
                        languageCode={languageCode}
                      >
                        <BookingPage />
                      </T9nProvider>
                    </ApolloContextProvider>
                  }
                />
                <Route
                  path={ROUTES.UNSUBSCRIBE}
                  element={
                    <ApolloContextProvider
                      countryCode={countryCode}
                      languageCode={languageCode}
                    >
                      <T9nProvider
                        countryCode={countryCode}
                        languageCode={languageCode}
                      >
                        <EmailsUnsubscribe />
                      </T9nProvider>
                    </ApolloContextProvider>
                  }
                />
                <Route
                  path={ROUTES.FORBIDDEN}
                  element={
                    <ApolloContextProvider
                      countryCode={countryCode}
                      languageCode={languageCode}
                    >
                      <T9nProvider
                        countryCode={countryCode}
                        languageCode={languageCode}
                      >
                        <ForbiddenErrorPage />
                      </T9nProvider>
                    </ApolloContextProvider>
                  }
                />
                <Route
                  path="*"
                  element={
                    <FeatureFlagProvider>
                      <MaintenancePageWrapper
                        countryCode={countryCode}
                        languageCode={languageCode}
                      >
                        <AuthProvider {...oidcConfig}>
                          <SecureApolloContextProvider
                            countryCode={countryCode}
                            languageCode={languageCode}
                            isPreview={isPreview}
                          >
                            <T9nProvider
                              countryCode={countryCode}
                              languageCode={languageCode}
                            >
                              <App
                                countryCode={countryCode}
                                languageCode={languageCode}
                                isPreview={!!isPreview}
                              />
                            </T9nProvider>
                          </SecureApolloContextProvider>
                          <LoginTracker />
                        </AuthProvider>
                      </MaintenancePageWrapper>
                    </FeatureFlagProvider>
                  }
                />
              </Routes>
            </Suspense>
          </FatalErrorHandlerProvider>
        </BrowserRouter>
      </PageTitleProvider>
    </GoogleAnalyticsProvider>
  </ThemeProvider>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
