import { PropertyList } from 'types/app';
import { ReapitEntityTypes, ReapitDocument } from 'views/Documents/types';

export const getFilteredDocumentList = (
  documentList: ReapitDocument[],
  showLettings: boolean,
  propertyList: PropertyList,
) => {
  const allPropertyIds =
    propertyList?.selling?.map((property) => property.id) || [];

  if (!documentList) {
    return [];
  }

  if (showLettings) {
    return documentList;
  }
  return documentList.filter(
    (doc) =>
      allPropertyIds.includes(doc.associatedId) ||
      doc.associatedType !== ReapitEntityTypes.PROPERTY,
  );
};
