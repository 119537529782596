import { TagProps } from 'components/Card/types';
import { PriceConfigProps } from 'components/SearchPropertyCards/types';
import { Image } from './app';

interface PriceHistoryProps {
  date: string;
  changes: {
    previousValue: string;
    currentValue: string;
  };
}

export type AgentType = {
  name: string;
  telephone?: string;
  email: string;
  href?: string;
  image: string;
  smallImage?: string;
  workPhone?: string;
  mobilePhone?: string;
  profileImageUrl?: string;
};

export enum MarketingPortals {
  SAVILLS = 'savillsWebsite',
  RIGHTMOVE = 'rightmove',
  ZOOPLA = 'zoopla',
}

export interface Property {
  reapitId: string;
  externalPropertyId: string;
  mainPrice?: string;
  acceptedPrice?: string;
  guidePrice?: string;
  geoLocation: google.maps.LatLngLiteral;
  sellingStatus?: SellingStatus;
  lettingStatus?: LettingStatus;
  qualifier?: string;
  area?: Area;
  internalArea?: string;
  externalArea?: string;
  address: Address;
  bedrooms: number;
  bathrooms: number;
  receptions: number;
  tenure?: Tenure;
  type: PropertyType;
  images: Image[];
  floorplans?: Image[];
  tags?: TagProps[];
  negotiator?: Negotiator;
  savillsWebsiteUrl?: string;
  rent?: number;
  availableFrom?: string;
  availableTo?: string;
  agentRole?: string;
  rentFrequency?: string;
  instructionRequested?: boolean;
  summary?: string[];
  internetAdvertising?: boolean;
  isCommercial?: boolean;
  isNewDevelopment?: boolean;
  isFarm?: boolean;
  priceConfig?: PriceConfigProps;
  featureList?: string[];
  countryId?: string;
  officeName: string;
  propertyTypes: string[];
  priceHistory: PriceHistoryProps[];
  isScottishProperty?: boolean;
  isGDPRProperty?: boolean;
  closingDate?: string;
  isParent?: boolean;
  newDevelopmentBedrooms?: string;
  newDevelopmentBathrooms?: string;
  newDevelopmentReceptions?: string;
  agents?: Agent[];
  labels: Label[];
  officeGrade?: string;
  officePeople?: string;
  marketingPortals?: MarketingPortals[];
}

export interface PropertyAddress {
  id: string;
  address: string;
}

export enum SellingStatus {
  PreAppraisal = 'preAppraisal',
  ForSale = 'forSale',
  ForSaleUnavailable = 'forSaleUnavailable',
  ForSaleLostMarket = 'forSaleLostMA',
  UnderOffer = 'underOffer',
  UnderOfferUnavailable = 'underOfferUnavailable',
  Exchanged = 'exchanged',
  SoldExternally = 'soldExternally',
  Withdrawn = 'withdrawn',
  Completed = 'completed',
  Valuation = 'valuation',
  PaidValuation = 'paidValuation',
  Reserved = 'reserved',
}

export enum LettingStatus {
  Valuation = 'valuation',
  ToLet = 'toLet',
  ToLetUnavailable = 'toLetUnavailable',
  UnderOffer = 'underOffer',
  UnderOfferUnavailable = 'underOfferUnavailable',
  ArrangingTenancy = 'arrangingTenancy',
  ArrangingTenancyUnavailable = 'arrangingTenancyUnavailable',
  TenancyCurrent = 'tenancyCurrent',
  TenancyCurrentUnavailable = 'tenancyCurrentUnavailable',
  TenancyFinished = 'tenancyFinished',
  TenancyCancelled = 'tenancyCancelled',
  Sold = 'sold',
  Withdrawn = 'withdrawn',
  LetByOtherAgent = 'letByOtherAgent',
  LetPrivately = 'letPrivately',
  Provisional = 'provisional',
}

export enum PropertyAttribute {
  Bedrooms = 'Bedrooms',
  Receptions = 'Receptions',
  Bathrooms = 'Bathrooms',
  OfficeGrade = 'OfficeGrade',
  OfficePeople = 'OfficePeople',
}

export type Address = {
  line1: string;
  line2: string;
  postcode?: string;
  buildingNumber?: string;
  buildingName?: string;
};

export enum Tenure {
  Buy = 'Buy',
  Rent = 'Rent',
}

export type Price = {
  currency: string;
  displayCurrency: string;
  min: number;
  max: number;
};

export type PropertySize = {
  measurement: string;
  min: number;
  max: number;
};

export type SearchArea = {
  coordinates: number[];
  locationId: string;
  radius: string;
  type: string;
};

export type Area = {
  size: string;
  type: AreaType;
};
export enum LabelType {
  Neutral = 'neutral',
  Alert = 'alert',
  NewDevelopment = 'new-development',
  Farm = 'farm',
  Commercial = 'commercial',
  Default = 'default',
  SharedOwnership = 'shared-ownership',
  MarketType = 'market-type',
}

export enum AreaType {
  PropertySize = 'PropertySize',
  LandSize = 'LandSize',
}

export enum PropertyType {
  House = 'house',
  Bungalow = 'bungalow',
  FlatApartment = 'flat-apartment',
  Maisonette = 'maisonette',
  Land = 'land',
  Farm = 'farm',
  Cottage = 'cottage',
  Studio = 'studio',
  Townhouse = 'townhouse',
  DevelopmentPlot = 'development-plot',
  NewDevelopment = 'new-development',
  Duplex = 'duplex',
  Penthouse = 'penthouse',
  Vineyard = 'vineyard',
  Retail = 'retail',
  BuildingPlot = 'building-plot',
  Chalet = 'chalet',
  Compound = 'compound',
  Condominium = 'condominium',
  CountryHouse = 'country-house',
  ExecutiveCondominium = 'executive-condominium',
  FlatOutsideCity = 'flat-outside-city',
  HDBApartment = 'hdb-apartment',
  HighRiseBuilding = 'high-rise-building',
  MiddleRiseBuilding = 'middle-rise-building',
  ServicedApartment = 'serviced-apartment',
  TemporaryUseApartments = 'temporary-use-apartments',
  Triplex = 'triplex',
  Villa = 'villa',
  Castle = 'castle',
  Estate = 'estate',
  Equestrian = 'equestrian',
  Mews = 'mews',
  DevelopmentLand = 'development-land',
  Development = 'development',
  Industrial = 'industrial',
  Leisure = 'leisure',
  Office = 'office',
  Hotel = 'hotel',
  Heathcare = 'heathcare',
  Investment = 'investment',
  ServicedOffice = 'serviced-office',
  Sporting = 'sporting',
  Woodland = 'woodland',
  ArableFarm = 'arable-farm',
  BareLand = 'bare-land',
  DairyFarm = 'dairy-farm',
  LivestockFarm = 'livestock-farm',
  ResidentialFarmEstate = 'residential-farm-estate',
  Laboratory = 'laboratory',
  Healthcare = 'healthcare',
  BusinessPark = 'business-park',
  FlexSpace = 'flex-space',
  SeniorLiving = 'senior-living',
}

type Negotiator = {
  id: string;
  email: string;
  workPhone?: string;
  mobilePhone: string;
  name: string;
  profileImageUrl: string;
  officeId?: string;
};

export type Agent = {
  id: string;
  name: string;
  phoneNumber: string;
  email: string;
  profileImageUrl: string;
  office: string;
  isPrimary: boolean;
};

type Label = {
  type?: string;
  value?: string;
  resource?: string;
};

export type PropertyOffers = {
  id: string;
};

export enum AppointmentCodes {
  AP = 'AP',
  CD = 'CD',
  CI = 'CI',
  CO = 'CO',
  ES = 'ES',
  FA = 'FA',
  HV = 'HV',
  HY = 'HY',
  IA = 'IA',
  MA = 'MA',
  ME = 'ME',
  MI = 'MI',
  OH = 'OH',
  OV = 'OV',
  PI = 'PI',
  PL = 'PL',
  RV = 'RV',
  RR = 'RR',
  SY = 'SY',
  VL = 'VL',
  VW = 'VW',
}

export type Appointment = {
  reapitId: string;
  propertyId: string;
  typeId: string;
  start: string;
  end: string;
  associatedOffices: string[];
  description: string;
  accompaniedByNeg: boolean;
  isCancelled: boolean;
  isConfirmed: boolean;
  isRecurring: boolean;
  isVirtual: boolean;
  negotiatorIds: string[];
  negotiators: {
    id: string;
    name: string;
    workPhone: string;
    email: string;
  }[];
  followUp: {
    due: string;
    notes: string;
  };
  applicant: {
    name: string;
  };
};

export enum PropertyViewTypes {
  VENDOR = 'Vendor',
  LANDLORD = 'Landlord',
}

export enum PropertyPageComponents {
  DOCUMENTS = 'documents',
  FEEDBACK = 'feedback',
  NEGOTIATOR_CARD = 'negotiatorCard',
  MARKETING_STATS = 'marketingStats',
  CALENDAR = 'calendar',
  TASKS = 'tasks',
  OFFERS = 'offers',
  INSTRUCT = 'instruct',
  AGENT = 'agent',
}

export enum PropertyCardTag {
  Commercial = 'Commercial',
  NewDevelopment = 'NewDevelopment',
  Farm = 'Farm',
  Residential = 'Residential',
  SharedOwnership = 'SharedOwnership',
  AvailableInLots = 'AvailableInLots',
  CommercialScheme = 'CommercialScheme',
  RetailScheme = 'RetailScheme',
  FeaturedProperties = 'FeaturedProperties',
}
