import { MarketingStat } from 'components/MarketingReport/types';
import { NavAddress, SlimProperty } from 'types/app';
import { MarketingPortals, Property } from 'types/property';

export const getSuffix = (arr: SlimProperty[]) => {
  return arr.length ? `( ${arr.length} )` : '';
};

export const formatStreetAddress = ({
  buildingName,
  buildingNumber,
  line1,
  postcode,
}: NavAddress) => {
  let streetAddress = '';
  if (buildingName) {
    streetAddress += `${buildingName}, `;
  }
  if (buildingNumber) {
    streetAddress += `${buildingNumber}`;
  }
  if (line1) {
    if (buildingNumber) {
      streetAddress += ' ';
    }
    streetAddress += `${line1}`;
  }
  if (postcode) {
    streetAddress += `, ${postcode}`;
  }
  return streetAddress;
};

export function debounce<T>(fn: (...args: T[]) => void, ms = 250) {
  let timer: ReturnType<typeof setTimeout> | null = null;
  return (...args: T[]) => {
    if (timer) clearTimeout(timer);
    timer = setTimeout(() => {
      fn(...args);
      timer = null;
    }, ms);
  };
}

export function getVutureApiUrl() {
  return window.appConfig.vutureHeaderUri;
}

export const getMarketingPortals = (
  marketingStats: MarketingStat[],
  property: Property,
) => {
  const portals = [];
  if (
    marketingStats.find(
      (stat) => stat.rightMovePageViews || stat.rightMoveSearchImpressions,
    )
  ) {
    portals.push(MarketingPortals.RIGHTMOVE);
  }

  if (
    marketingStats.find(
      (stat) => stat.zooplaPageViews || stat.zooplaSearchImpressions,
    )
  ) {
    portals.push(MarketingPortals.ZOOPLA);
  }

  if (
    marketingStats.find(
      (stat) => stat.savillsPageViews || stat.savillsSearchImpressions,
    ) ||
    property.savillsWebsiteUrl
  ) {
    portals.push(MarketingPortals.SAVILLS);
  }

  return portals;
};
