import styled from 'styled-components';

export const SharedHeading = styled.h3`
  font-family: ${(props) => props.theme.fonts.primary.font};
  font-size: 24px;
  font-weight: 400;
  margin-bottom: 0;

  span {
    margin: 16px 0;
    vertical-align: middle;
    font-size: 16px;
    font-weight: 200;
    line-height: 0;
  }

  @media screen and (${({ theme }) => theme.media.widthSml}) {
    flex-direction: row;
  }

  @media screen and (${(props) => props.theme.media.widthLtSml}) {
    font-size: ${(props) => props.theme.typography.large};
  }
`;

export const SubHeading = styled(SharedHeading)`
  font-size: 18px;
`;

export const SharedSection = styled.section`
  margin-bottom: 48px;

  p {
    font-family: ${(props) => props.theme.fonts.secondary.font};
    font-weight: 300;
  }
`;

export const SharedHeader = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  margin-bottom: 20px;

  @media screen and (${({ theme }) => theme.media.widthSml}) {
    flex-direction: row;
  }
`;

export const SharedListItem = styled.li`
  margin-bottom: 40px;
`;

export const NoResultsContainer = styled.div`
  font-family: ${(props) => props.theme.fonts.secondary.font};
  font-weight: ${(props) => props.theme.fonts.secondary.fontWeight};
  font-size: ${(props) => props.theme.typography.small};
  background: ${(props) => props.theme.colorPalette.branding.white};
  padding: 20px;
  position: relative;
  width: 100%;
  display: flex;
  align-items: center;
  grid-gap: 10px;

  &::before {
    content: '';
    position: absolute;
    border-radius: 0;
    top: 0;
    left: 0;
    width: 5px;
    height: 100%;
    background: ${(props) => props.theme.colorPalette.secondary.red};
  }

  p {
    margin: 0;
  }
`;

export const SharedCopy = styled.p`
  font-family: ${(props) => props.theme.fonts.secondary.font};
  font-weight: 300;
  font-size: ${(props) => props.theme.typography.small};
  margin: 24px 0 36px;
`;
