import React, { useContext, useEffect } from 'react';
import { toast } from 'react-toastify';
import { T9nContext, withT9nHook } from 'providers/T9nProvider/t9nContext';
import { PageTitleContext } from 'providers/PageTitle/PageTitleContext';
import { AccountStateContext } from 'providers/Account/AccountState';
import { useAppData } from 'hooks/useAppData';
import { Headings } from 'savills-ui-kit';
import { broadcastRemoveSessionMessage } from 'services/logout';
import { getBaseUrl } from 'utils/helpers/misc/misc';
import errorT9nQuery from '../../queries/t9n/errorT9n.gql';
import {
  Logo,
  Header,
  MainContent,
  NoticePageWrapper,
  NoticePageBody,
  NoticeCTAs,
  NoticeLink,
} from '../../components/Notice/Notice.styled';

const ForbiddenErrorPage: React.FC = () => {
  const { t9n } = useContext(T9nContext);
  const { state } = useContext(AccountStateContext);

  const { logoUrl: homeUrl } = useAppData();
  const { setPageTitle } = useContext(PageTitleContext);
  useEffect(() => {
    setPageTitle(t9n.PageTitles.Error);
  }, [setPageTitle, t9n.PageTitles.Error]);

  const content = t9n.ErrorPages.Forbidden;

  const handleLogin = async () => {
    if (state.isPreview) {
      toast.error(t9n.PreviewMode.Notification);
      return;
    }
    await broadcastRemoveSessionMessage();
    window.location.href = getBaseUrl() ?? window.location.origin;
  };

  return (
    <div>
      <Header>
        <a href={homeUrl} aria-label="Savills home">
          <Logo title="Savills Logo" />
        </a>
      </Header>
      <MainContent>
        <NoticePageWrapper>
          <Headings.PageHeading>{content.Title}</Headings.PageHeading>
          <NoticePageBody>{content.Body}</NoticePageBody>
          <NoticeCTAs>
            <NoticeLink as="button" onClick={handleLogin}>
              {content.Cta}
            </NoticeLink>
          </NoticeCTAs>
        </NoticePageWrapper>
      </MainContent>
    </div>
  );
};

const postponeRenderT9nKeys = ['ErrorPages'];
export default withT9nHook(
  ForbiddenErrorPage,
  errorT9nQuery,
  postponeRenderT9nKeys,
);
