/* eslint-disable no-nested-ternary */
import { useQuery, useLazyQuery } from '@apollo/client';
import * as cheerio from 'cheerio';
import { useEffect, useMemo, useState } from 'react';
import { useFeatureIsOn } from '@growthbook/growthbook-react';
import { Profile, PropertyList } from 'types/app';
import { FeatureFlags } from 'utils/helpers/detection/types';
import { ReapitDocument } from 'views/Documents/types';
import { Task } from 'components/Tasks/types';
import appDataQuery from 'queries/appDataQuery.gql';
import maintenanceAppDataQuery from 'queries/maintenanceAppDataQuery.gql';
import documentsQuery from 'queries/documents/documentsQuery.gql';
import { getFilteredDocumentList } from 'utils/helpers/documents/documents';
import { SAVILLS_UK_HOME } from 'constants/routes';

const DEFAULT_PROPERTY_LIST = { selling: [], letting: [] };
const DEFAULT_PROFILE = { userId: '', viewingFeedbackVisible: false };

const getFilteredPropertyList = (
  propertyList: PropertyList,
  showLettings: boolean,
) => {
  if (!propertyList) {
    return DEFAULT_PROPERTY_LIST;
  }

  if (showLettings) {
    return propertyList;
  }

  return { selling: propertyList.selling, letting: [] };
};

export const useAppData = () => {
  const showLettings = useFeatureIsOn(FeatureFlags.LETTINGS as string);
  const isReapitMaintenanceMode = useFeatureIsOn(
    FeatureFlags.REAPIT_MAINTENANCE_MODE as string,
  );
  const [documentList, setDocumentList] = useState<ReapitDocument[]>([]);
  const [shouldReloadDocuments, reloadDocs] = useState<boolean>(false);
  const [tasks, setDocumentTasks] = useState<Task[]>([]);
  const [propertyLoading, setPropertyLoading] = useState<boolean>(true);
  const [propertyList, setPropertyList] = useState<PropertyList>(
    DEFAULT_PROPERTY_LIST,
  );
  const [profile, setProfile] = useState<Profile>(DEFAULT_PROFILE);

  const appQuery = isReapitMaintenanceMode
    ? maintenanceAppDataQuery
    : appDataQuery;

  const { loading, data } = useQuery(appQuery, {
    errorPolicy: 'all',
  });

  const [fetchDocuments, documentsData] = useLazyQuery<{
    documents: ReapitDocument[];
    documentTasks: Task[];
  }>(documentsQuery, {
    fetchPolicy: !shouldReloadDocuments ? 'cache-only' : 'cache-first',
  });

  useEffect(() => {
    if (shouldReloadDocuments && fetchDocuments) {
      fetchDocuments();
      reloadDocs(false);
    }
  }, [shouldReloadDocuments, showLettings, fetchDocuments]);

  useEffect(() => {
    if (data) {
      setDocumentList(
        getFilteredDocumentList(
          data.documents,
          showLettings,
          data.propertyList,
        ),
      );
      setPropertyList(getFilteredPropertyList(data.propertyList, showLettings));
      setPropertyLoading(false);
      setDocumentTasks(data.documentTasks || []);
      setProfile(data.profile || DEFAULT_PROFILE);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, showLettings]);

  const logoUrl = useMemo(() => {
    if (data?.logoUrl && data.logoUrl !== 'null') {
      return data.logoUrl;
    }
    return SAVILLS_UK_HOME;
  }, [data?.logoUrl]);

  const footerHeaderMarkup = useMemo(() => {
    if (
      data?.footerHeaderMarkup?.headerContent &&
      data?.footerHeaderMarkup?.footerContent
    ) {
      const $ = cheerio.load(data.footerHeaderMarkup.headerContent);
      const $modalHTML = $('.sv-cookie-management');
      return {
        ...data.footerHeaderMarkup,
        headerContent: $modalHTML.html(),
      };
    }
    return '';
  }, [data?.footerHeaderMarkup]);

  useEffect(() => {
    if (documentsData?.data?.documents) {
      setDocumentList(
        getFilteredDocumentList(
          documentsData.data.documents,
          showLettings,
          propertyList,
        ),
      );
    }
  }, [documentsData, showLettings, propertyList]);

  useEffect(() => {
    if (documentsData?.data?.documentTasks) {
      setDocumentTasks(documentsData.data.documentTasks);
    }
  }, [documentsData]);

  const refreshDocuments = () => {
    reloadDocs(true);
  };

  return {
    isLoading: loading || propertyLoading || documentsData.loading,
    documentList,
    propertyList,
    tasks,
    profile,
    logoUrl,
    footerHeaderMarkup,
    refreshDocuments,
  };
};
