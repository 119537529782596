import styled from 'styled-components';
import { ReactComponent as SavillsLogo } from 'assets/icons/savills-square.svg';
import { StyledLink } from 'components/StyledLink/StyledLink';

export const NoticePageWrapper = styled.div`
  padding: 0 40px 20px;
  max-width: 1000px;
  margin: 0 auto;

  @media screen and (${(props) => props.theme.media.widthSmlPhone}) {
    padding: 0 20px 20px;
  }
`;

export const NoticePageBody = styled.p`
  text-align: center;
  color: ${(props) => props.theme.colorPalette.branding.ink};
  font-family: ${(props) => props.theme.fonts.secondary.font};
  font-size: ${(props) => props.theme.typography.default};
  font-weight: ${(props) => props.theme.fonts.secondary.fontWeight};
  margin: 20px 0;
  width: 100%;
`;

export const NoticeCTAs = styled.div`
  display: flex;
  margin: 70px 0 0;
  grid-gap: 20px;
  width: 100%;
  flex-direction: column;
  justify-content: center;

  @media screen and (${(props) => props.theme.media.widthSmlPhone}) {
    flex-direction: row;
  }
`;

export const NoticeLink = styled(StyledLink)`
  font-family: ${(props) => props.theme.fonts.secondary.font};
  font-size: ${(props) => props.theme.typography.small};
  font-weight: ${(props) => props.theme.fonts.secondary.fontWeightMed};
  text-decoration: none;
  padding: 1em 2em;
  min-width: 14em;
  line-height: 1;
  min-height: 3.5em;
  max-width: 196px;
  background-image: linear-gradient(
    90deg,
    ${(props) => props.theme.colorPalette.branding.ink} 50%,
    ${(props) => props.theme.colorPalette.branding.yellow} 0
  );
  background-position: 100% 0;
  background-size: 200% auto;
  transition: background-position 0.25s, color 0.25s;
  color: ${(props) => props.theme.colorPalette.branding.ink};
  border-radius: 0;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  cursor: pointer;
  border: 0;

  &:disabled {
    background-image: none;
  }

  &:focus,
  &:hover {
    background-position: -10px 0;
    color: ${(props) => props.theme.colorPalette.branding.yellow};
  }
`;

export const Header = styled.header`
  background: ${(props) => props.theme.colorPalette.branding.ink};
  padding: 20px 30px;
  display: flex;
`;

export const MainContent = styled.main`
  display: flex;
  justify-content: center;
  align-items: center;
  height: calc(100vh - 100px);
  flex-direction: column;
  padding: 20px;
`;

export const Logo = styled(SavillsLogo)`
  width: 60px;
  height: 60px;
`;
