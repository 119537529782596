import React, { useContext, useMemo } from 'react';
import {
  Map,
  MapMarker,
  MAP_STYLES,
  MapContext,
  MapContextType,
} from 'savills-ui-kit';
import { MapContainer } from '../PropertyCard.styled';
import { DEFAULT_MAP_SETTINGS, MAP_PIN_URL } from './constants';
import { PropertyCardMapProps } from './types';

const PropertyCardMarker: React.FC<
  Pick<PropertyCardMapProps, 'propertyPosition'>
> = ({ propertyPosition }) => {
  const { map } = useContext<MapContextType>(MapContext);

  const propertyMarkerIcon: google.maps.Icon = useMemo(
    () => ({
      url: MAP_PIN_URL,
      scaledSize: new google.maps.Size(36, 36),
      anchor: new google.maps.Point(18, 36),
    }),
    [],
  );

  const onPropertyMarkerSet = () => {
    if (map) map.setCenter(propertyPosition);
  };

  return (
    <MapMarker
      position={propertyPosition}
      icon={propertyMarkerIcon}
      onMarkerSet={onPropertyMarkerSet}
    />
  );
};

const PropertyCardMap: React.FC<PropertyCardMapProps> = ({
  id,
  googleLoaded,
  propertyPosition,
  loadingText,
  zoom = DEFAULT_MAP_SETTINGS.zoom,
  hideMarker = false,
}) => {
  return (
    <MapContainer>
      {googleLoaded ? (
        <Map
          id={id}
          defaultZoom={zoom}
          defaultCenter={propertyPosition}
          gestureHandling={DEFAULT_MAP_SETTINGS.gestureHandling}
          scriptLoaded={googleLoaded}
          styles={MAP_STYLES as google.maps.MapTypeStyle[]}
          streetViewControl={false}
          fullscreenControl={false}
          clickableIcons={false}
        >
          {!hideMarker && (
            <PropertyCardMarker propertyPosition={propertyPosition} />
          )}
        </Map>
      ) : (
        <p>{loadingText}</p>
      )}
    </MapContainer>
  );
};

export default PropertyCardMap;
